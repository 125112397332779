import {store} from "@/store";
import gcoord from "gcoord";

const getValueGps = (id) => {
  let result = {}
  let value = store.getters['cables/getDeviceValueById'](id)
  result.longitude = value.gpsLongitude
  result.latitude = value.gpsLatitude
  return result
}

function isEmptyString(str) {
  return str.trim() === ''
}

function isValidGps(long, lat) {
  if (!long || !lat) {
    return false
  }
  return (!isEmptyString(long) && !isEmptyString(lat))
}

function gpsTemplate(obj) {
  return {longitude: obj.longitude, latitude: obj.latitude}
}

export const getCoordinate = (box) => {
  let boxGps = gpsTemplate(box)
  let staticGps,
      dynamicGps
  if (box.gpsmodel === 3) {
    for (let dev of box.subdevs) {
      if (dev.gpsmodel === 1 && !staticGps) {
        if (isValidGps(dev.longitude, dev.latitude)) {
          staticGps = gpsTemplate(dev)
        }
      } else if (dev.gpsmodel === 2) {
        let rst = getValueGps(dev.id)
        if (rst.longitude && rst.latitude && isValidGps(rst.longitude, rst.latitude)) {
          dynamicGps = gpsTemplate(rst)
          break
        }
      }
    }
  }
  // if (!isValidGps(boxGps.longitude, boxGps.latitude)) {
  //
  //   boxGps = {longitude: "119.823407",
  //       latitude: "31.340637",}
  // }
  if (dynamicGps) {
    let newCd = gcoord.transform(
        [dynamicGps.longitude, dynamicGps.latitude],
        gcoord.WGS84,
        gcoord.GCJ02
    );
    return {
      longitude: newCd[0],
      latitude: newCd[1],
    }
  } else if (staticGps) {
    return staticGps
  } else {
    return boxGps
  }
}

