<template>
  <dv-border-box-10>
    <div v-loading="loading" id="map-border">
      <div id="container"></div>
    </div>
    <div v-if="isCablecenter" class="toggle-drag" @click="!dragging? enableDrag() : undefined">
        <el-icon v-if="!dragging" color="black"><Tools /></el-icon>
        <el-popconfirm
            v-else
            :title="this.$t('mapCables.positionChangeQ')"
            :confirm-button-text="this.$t('mapCables.positionChangeY')"
            :cancel-button-text="this.$t('mapCables.positionChangeN')"
            :icon="InfoFilled"
            icon-color="#626AEF"
            @confirm="confirmDrag"
            @cancel="cancelDrag"
        >
          <template #reference>
            <el-icon color="green"><CircleCheckFilled /></el-icon>
          </template>
        </el-popconfirm>
    </div>
  </dv-border-box-10>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import DataVVue3 from '@kjgl77/datav-vue3'
import ElementPlus, {ElMessage} from "element-plus";
import InfoWindow from "./infoWindow.vue";
import MarkerContent from "./markerContent.vue";
import { shallowRef } from "@vue/reactivity";
import { createApp, reactive } from "vue";
import { store } from "@/store";
import { mapState } from "vuex"
import router from '@/router'
import { getCoordinate } from "./gps";
import {useRoute} from "vue-router";
import {updateDevice} from "@/api/devices";
import i18n from '@/i18n'
import { useI18n } from 'vue-i18n'

export default {
  name: "mapCables",

  data() {
    const map = shallowRef(null);
    const AMap = shallowRef(null);
    const Loca = shallowRef(null);
    const infoWindow = shallowRef(null);
    const infoWindowDiv = shallowRef(null);
    const infoWindowCmp = shallowRef(null);
    const deviceMapItems = new Map();
    const devices = new Map();
    const markerContentCmps = new Map();
    const markers = [];
    const markersMap = new Map();
    const layer = shallowRef(null);
    const locaContainer = shallowRef(null);
    const geo = shallowRef(null);
    const timer = shallowRef(null);
    let boxesDragged = []
    let originalPositions = {}
    const dragging = shallowRef(false)
    const loading = shallowRef(false)
    return {
      AMap,
      Loca,
      map,
      locaContainer,
      layer,
      geo,
      infoWindow,
      infoWindowDiv,
      infoWindowCmp,
      markerContentCmps,
      markers,
      markersMap,
      timer,
      devices,
      timerCount: 0,
      deviceMapItems, //key : uid   value: device + mapItem
      loadCount:0,
      boxesDragged,
      originalPositions,
      dragging,
      loading,
    };
  },
  setup() {
    window.forceWebGL = true;
    const route = useRoute();
    const { $t } = useI18n()
    return {
      isCablecenter: route.name === "cablecenter",
      $t,
    };
  },
  methods: {
    async initMap() {
      AMapLoader.load({
        key: "f089ce7d4b2cbeb6af07463b21e620e1", //设置您的key
        version: "2.0",
        plugins: ["AMap.ToolBar", "AMap.Scale"],
        AMapUI: {
          version: "1.1",
          plugins: [],
        },
        Loca: {
          version: "2.0.0",
        },
      }).then((AMap) => {
        this.AMap = AMap;//全局变量
        this.Loca = Loca;//全局变量
        this.map = new AMap.Map("container", {
          viewMode: "2D",
          zoom: 3,
          //zooms: [2, 22],
          //center: [105.602725, 37.076636],
          mapStyle: "amap://styles/grey", //设置地图的显示样式
        });
        let scale = new AMap.Scale();
        let toolBar = new AMap.ToolBar({
          position: {
            top: "110px",
            right: "40px",
          },
        });
        this.map.addControl(scale);
        this.map.addControl(toolBar);
        // window.onClickByMap = this.onClickByMap;
        // window.onCompleteByMap = this.onCompleteByMap;
        this.map.on("click", this.onClickByMap);
        this.map.on("complete", this.onCompleteByMap);
      }).catch((e) => {
        console.log(e);
      });
    },
    onCompleteByMap(e) {
      if(this.loadCount == 0)
      {
        this.map.clearMap();
        this.map.destroy();
        this.map = null;
        this.initMap();
      }
      else
      {
        this.infoWindow = new this.AMap.InfoWindow({
          offset: new this.AMap.Pixel(0, -30),
          isCustom: true,
        });
        let status = store.state.cables.actionStatus
        if (status.all > 1 && status.values > 1) {
          this.fillMapObjects(this.cables.list);
        }
      }
      this.loadCount++;
    },
    onClickByMap(e) {
      if (this.infoWindow && this.infoWindow.getIsOpen()) {
        this.infoWindow.close();
      }
    },
    expandBox(marker){
      if (!marker) {
        return
      }
      let exData = marker.getOptions().extData;
      let device = exData.device;
      this.infoWindow.setExtData({
        device: device,
        uuid: device.uuid,
      });
      this.createInfoWindow(
          this.map,
          "title uuid:" + device.uuid,
          device
      );
      this.infoWindow.setContent(this.infoWindowDiv);
      this.infoWindow.open(this.map, marker.getPosition());
    },
    markerClickFunction(e) {
      let boxId = e.target.getOptions()?.extData?.device?.id
      this.$store.commit("cables/setBoxClicked", boxId);
      this.expandBox(e.target);
    },
    // 拖拽相关
    enableDrag() {
      this.$store.commit("cables/setDrag", true);
      this.dragging = true
      for (let m of this.markers) {
        m.setDraggable(true)
      }
    },
    cancelDrag() {
      for (let m of this.markers) {
        let device = m.getExtData().device
        if (this.boxesDragged.indexOf(device.id) > -1) {
          m.setPosition(this.originalPositions[device.id])
        }
      }
      this.disableDrag()
    },
    disableDrag() {
      this.$store.commit("cables/setDrag", false);
      this.dragging = false
      this.boxesDragged = []
      this.originalPositions = {}
      for (let m of this.markers) {
        m.setDraggable(false)
      }
    },
    updatePosition(id, longitude, latitude) {
      if (typeof longitude === "number") {
        longitude = longitude.toFixed(6)
      }
      if (typeof latitude === "number") {
        latitude = latitude.toFixed(6)
      }
      return updateDevice({
        id,
        longitude,
        latitude,
      }).then((r)=>{
        if (r.code === 0) {
          ElMessage({
            type: 'success',
            message: this.$t('mapCables.positionChangeSuccess')
          })
        }
      })
    },
    async confirmDrag() {
      this.loading = true
      let waits = []
      for (let i of this.boxesDragged) {
        let d = this.$store.getters["cables/getDeviceById"](i)
        let markerPos = this.markersMap.get(i).getPosition()
        let wait = this.updatePosition(d.id, markerPos.lng, markerPos.lat)
        waits.push(wait)
      }
      let currentCable = this.$store.getters['cables/getById'](this.cables.current)
      for (let i of currentCable.gndboxs) {
        if (this.boxesDragged.indexOf(i.id) > -1) {
          continue
        }
        let marker = this.markersMap.get(i.id)
        if (!marker) {
          continue
        }
        let lng = marker.getPosition().lng.toFixed(6),
            lat = marker.getPosition().lat.toFixed(6)
        if (i.longitude !== lng || i.latitude !== lat) {
          let wait = this.updatePosition(i.id, lng, lat)
          waits.push(wait)
        }
      }
      if (waits.length === 0) {
        this.disableDrag()
        this.loading = false
        return
      }
      await Promise.all(waits).then(
          async ()=>{
            await this.$store.dispatch('cables/request', true)
                .then(() => {
                  this.disableDrag()
                  this.loading = false
                  this.removeLayer(this.cables.current)
                  let layer = this.addLayer(this.$store.getters['cables/getById'](this.cables.current))
                  this.deviceMapItems.set(this.cables.current, layer)
                  layer.show()
                })
          }
      )
    },
    onDragStart(e) {
      let device = e.target.getOptions().extData.device
      if (this.boxesDragged.indexOf(device.id) === -1) {
        this.boxesDragged.push(device.id)
        this.originalPositions[device.id] = e.target.getPosition()
      }
    },
    //构建自定义信息窗体
    createInfoWindow(map, title, device) {
      let info = document.createElement("div");
      let divId = "infoWindowMiddle" + device.uuid;
      info.setAttribute("id", divId);

      if (this.infoWindowCmp) {
        this.infoWindowCmp.unmount();
      }

      this.infoWindowCmp = createApp(InfoWindow, {
        map: this.map,
        device: device,
      });
      this.infoWindowCmp.use(ElementPlus);
      this.infoWindowCmp.use(DataVVue3);
      this.infoWindowCmp.use(store);
      this.infoWindowCmp.use(router);
      this.infoWindowCmp.use(i18n);
      this.infoWindowCmp.mount(info);
      this.infoWindowDiv = info;
    },
    createMarkerDiv1(device) {
      // 创建点实例
      let dev_id = "markerContent-" + device.uuid;
      let div = document.createElement("div");
      div.setAttribute("id", dev_id);
      div.style.width = "45px";
      div.style.height = "45px";

      let markerContentCmp = createApp(MarkerContent, {
        id: device.id,
        showName: this.isCablecenter,
      });
      markerContentCmp.use(store);
      markerContentCmp.use(i18n);
      markerContentCmp.mount(div);
      return div;
    },
    addMarker(device) {
      if(!this.AMap || !device )  {
       return;
      }
      let cd = getCoordinate(device)
      let lat = cd.latitude * 1;
      let lon = cd.longitude * 1;

      if(cd.latitude == undefined || lat == 0 || cd.longitude  == undefined ||  lon == 0 ) {
        return;
      }

      let AMap = this.AMap;
      let map = this.map;

      let marker = new AMap.Marker({
        position: new AMap.LngLat(lon, lat),
        content: this.createMarkerDiv1(device),
        anchor: "bottom-center",
        offset: [0,8],
        map,
        visible : false,
        extData: {
          id: device.name,
          uuid: device.uuid,
          device: device,
        },
      });
      window.markerClickFunction = this.markerClickFunction;
      marker.on("click", window.markerClickFunction);
      marker.on("dragstart", this.onDragStart)
      return marker;
    },
    getGeoJSONSource(cable) {
      let Loca = this.Loca;
        if(!cable || !cable.gndboxs) {
          return;
        }
        let ground_boxes = cable.gndboxs;
        let coordinates = [];
        ground_boxes.sort(function(a,b){return a.queue - b.queue})
        for (let d in ground_boxes) {
          if (!ground_boxes[d].enable) {
            continue
          }
          let cd = getCoordinate(ground_boxes[d])
          if(cd.longitude * 1 === 0 || cd.latitude * 1 === 0 ) {
            continue
          }
          coordinates.push([cd.longitude, cd.latitude]);
        }
        if(coordinates.length === 0) {
          return undefined;
        }
      // 创建数据源
        let feature = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        };
      let dataSource = new Loca.GeoJSONSource({
        data: {
          type: "FeatureCollection",
          features: [feature],
        },
      });

      return dataSource;
    },


    addLayer(cable) {
      if(!cable) {
        return;
      }
      let geo = this.getGeoJSONSource(cable);
      if (!geo) {
        return undefined;
      }
      let Loca = this.Loca;
      let layer = new Loca.PulseLineLayer({
        // locaContainer,
        zIndex: 10,
        opacity: 1,
        visible: false,
        zooms: [2, 22],
      });

      layer.setStyle({
        altitude: 0,
        lineWidth: 3,
        // 脉冲头颜色
        headColor: "#00BFFF",
        // 脉冲尾颜色
        trailColor: "rgba(128, 128, 128, 0.5)",
        // 脉冲长度，0.25 表示一段脉冲占整条路的 1/4
        interval: 0.5,
        // 脉冲线的速度，几秒钟跑完整段路
        duration: 15000,
      });
      layer.setSource(geo);

      this.locaContainer.add(layer);
      return layer;
    },
    addPolyLineLayer(cables) {
      let Loca = this.Loca;
      let map = this.map;
      if (!this.locaContainer) {
        let locaContainer = new Loca.Container({
          map,
        });
        window._loca = locaContainer;
        window.loca = locaContainer;
        this.locaContainer = locaContainer;
      }

      cables.forEach(cable => {
        let layer = this.deviceMapItems.get(cable.id);
        if (!layer) {
          layer = this.addLayer(cable);
          if (layer) {
            this.deviceMapItems.set(cable.id, layer);
          }
        }

        if (layer) {
          if (this.isCablecenter && cable.id !== this.cables.current) {
            layer.hide();
          }
          else {
            layer.show();
          }
        }
      });
      this.locaContainer.animate.start();
    },
    removeLayer(cableId) {
      let layer = this.deviceMapItems.get(cableId)
      this.locaContainer.remove(layer);
    },
    addMarkers(cables) {
      let markers = [];
      let markersMap = new Map()
      if(!cables) {
        return markers;
      }
      for (let i = 0; i < cables.length; i++) {
        let cable = cables[i];
        // let cableRef = this.getAndUpdateDeviceData(cable);
        let ground_boxes = cable.gndboxs;

        for (let j = 0; j < ground_boxes.length; j++) {
          let groundBox = this.getAndUpdateDeviceData(ground_boxes[j]);
          if (!groundBox.enable) {
            continue
          }
          let marker = this.deviceMapItems.get(groundBox.uuid);
          if (!marker) {
            marker = this.addMarker(groundBox);
            if(marker) {
              this.deviceMapItems.set(groundBox.uuid, marker);
            }
            else {
              continue;
            }
          }
          markersMap.set(groundBox.id, marker)
          if (this.isCablecenter && cable.id !== this.cables.current) {
            marker.hide();
          }
          else {
            marker.show();
            markers.push(marker);
          }
        }
      }
      this.markers = markers;
      this.markersMap = markersMap;
    },
    fillMapObjects(cables) {
      if(!this.AMap) {
        return;
      }
      if (!cables) {
        return;
      }
      this.addMarkers(cables);
      //添加脉冲线
      this.addPolyLineLayer(cables);
      this.map.setFitView(this.markers, true, [60, 60, 200, 200])
    },
    deviceDataUpdate(oldDevice, newDevice) {
      oldDevice.name = newDevice.name;
      oldDevice.color = newDevice.color;
      oldDevice.latitude = newDevice.latitude;
      oldDevice.longitude = newDevice.longitude;
    },
    getAndUpdateDeviceData(deviceIn) {
      if (!deviceIn) {
        return {};
      }
      let device = this.devices.get(deviceIn.uuid);
      if (!device) {
        device = reactive(deviceIn);
        this.devices.set(device.uuid, device);
      } else {
        this.deviceDataUpdate(device, deviceIn);
      }
      return device;
    },
  },
  updated() {
    this.mounted();
  },
  mounted() {
    // store.dispatch("models/request",false);
    store.dispatch("companies/request",false);
    store.dispatch('cables/request',false);
    this.initMap();
  },
  unmounted() {
    //console.log("unmounted into");
    clearInterval(this.timer);
    this.deviceMapItems.clear();
    if (this.map) {
      this.map.clearMap();
      this.map.destroy();
    }
  },
  errorCaptured(err, vm, info){
    console.log(err)
    // -> 错误返回
    console.log(vm)
    // -> vue实例
    console.log(info)
    // -> 在哪个钩子发生错误
    return false
  },
  watch: {
    "$store.state.cables.actionStatus": {
      deep: true,
      handler: function (status) {
        if (status.all > 1 && status.values > 1) {
          this.fillMapObjects(this.cables.list);
        }
      },
    },
    "$store.state.cables.current": {
      handler: function () {
        if (this.dragging) {
          this.cancelDrag()
        }
        this.fillMapObjects(this.cables.list);
      },
    },
    "$store.state.cables.boxClicked": {
      handler: function (val, oldVal) {
        if (val === oldVal) {
          return
        }
        let marker = this.markersMap.get(val)
        this.expandBox(marker)
      },
    },
  },
  computed: {
    ...mapState(
      ['cables','models']
    ),
    currentCable:{
      get() {
        return this.$store.state.cables.current;
      },
      set(value) {
        this.$store.commit("cables/setCurrent", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.home_div {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 101px;
  padding: 0px;
  margin: auto;
  border: 1px solid #f50808;
}
.map-title {
  top: 0px;
  z-index: 1;
  width: 10%;
  height: 50px;
  margin: auto;
  background-color: rgba(27, 25, 27, 0.884);
  border: 2px solid #17c920;
}
#map-border {
  /* border: 1px solid #3911ec; */
  width: 100%;
  height: 100%;
  padding: 5px;
  margin: auto;
  position: relative;
}
#container {
  height: 100%;
  width: 100%;
  /* border: 1px solid #cfb3e9;*/
}
h3 {
  left: 10px;
  z-index: 2;
  color: white;
  border: 2px solid #aff50d;
}

.toggle-drag {
  position: absolute;
  top: 80px;
  right: 45px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 4px;
}

.toggle-drag :hover {
  transform: scale(110%);
}
</style>
