<template>
  <BoxIcon :id="id" :shadow="true" size="big" />
  <div class="name-tag-container" v-show="props.showName">
    <div class="name-tag">{{ name }}</div>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {ref, watch, onMounted} from "vue";
import BoxIcon from '@/components/pmsIcon/BoxIcon.vue'

const props = defineProps(['id', 'showName'])

const store = useStore()

const name = ref('')

function load() {
  name.value = store.getters['cables/getDeviceById'](props.id).name
}

onMounted(() => {
  load()
})

watch(() => store.state.cables.actionStatus.all,
    () => {
      load()
    }
)
</script>

<style scoped>
.name-tag {
  flex-shrink: 0;
  text-shadow: 2px 2px 2px black;
}

.name-tag-container {
  display: flex;
  justify-content: center;
}
</style>
