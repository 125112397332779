<template>
  <dv-border-box-12 id="infoWindowBorder" :key="key"
                    :color="['rgba(11, 195, 241, 0.5)', isDeviceAlarm(device.id) ? '#DC143C' : '#7FFFD4']">
    <div
        id="div-infoWindow"
        @mouseover="onMouseOver"
        @mouseleave="onMouseLeave"
    >
      <div class="boxTitle">
        <el-link @click="this.$store.commit('cables/setCurrent', device.cableId); this.$router.push('cablecenter')">
          {{ getInfoBoxTitle(
            getCableName(getMapItemById(cables.idMap, device.cableId)),
            device.name) }}
          ({{ device.deviceCode }})
        </el-link>
<!--        <el-tooltip effect="dark" placement="top-start" :disabled="this.$route.name!=='cablecenter'">-->
<!--          <template #content>-->
<!--            <div @click="copyCode(device.deviceCode)">-->
<!--              {{ translate('mapCables.deviceCode') }}: {{ device.deviceCode }}-->
<!--            </div>-->
<!--          </template>-->
<!--        </el-tooltip>-->
      </div>
      <el-collapse
          class="collapse"
          accordion
          v-model="activeName"
          @change="onChange"
          style="justify-content: center"
      >
        <el-collapse-item v-for="subDevice in device.subdevs" :name="subDevice.id" :key="subDevice.name">
          <template #title>
            <div class="deviceicon">
              <DeviceIcon :id="subDevice.id" />
            </div>
            <el-link :underline="false" @click="onClick(subDevice.id)">
              {{ translate('dbDeviceModels.'+subDevice.model.name) }}
            </el-link>
<!--            <el-tooltip effect="dark" placement="left" :disabled="this.$route.name!=='cablecenter'">-->
<!--              <template #content>-->
<!--                <div @click="copyCode(subDevice.deviceCode)">-->
<!--                  {{ translate('mapCables.deviceCode') }}: {{ subDevice.deviceCode }}-->
<!--                </div>-->
<!--              </template>-->
<!--            </el-tooltip>-->
            <div>
              {{ updatedAt(subDevice.id) }}
            </div>
            <!--            <el-link type="primary" @click="onClick(subDevice.id)" ><el-icon><Link color="white"/></el-icon></el-link>-->

            <!--            <StatusTag class="tag"-->
            <!--                       :online="getDeviceValuesById(subDevice.id).online"-->
            <!--                       :isAlarming="isDeviceAlarm(subDevice.id)"-->
            <!--                       :numAlarms="getDeviceAlarmsById(subDevice.id).list.length"-->
            <!--            />-->

          </template>
          <el-scrollbar max-height="300px">
            <el-descriptions size="small" :column="1" border>
              <!--              <el-descriptions-item label="设备类型" span="2">-->
              <!--                {{ subDevice.model.name }}-->
              <!--              </el-descriptions-item>-->
              <!--              <el-descriptions-item label="数据时间" span="2">-->
              <!--                {{ formatTime(getDeviceValuesById(subDevice.id).time * 1000) }}-->
              <!--              </el-descriptions-item>-->
              <template v-for="deviceCfg in getDeviceValuesById(subDevice.id).devCfgs" :key="deviceCfg.dataUnit.name">
                <el-descriptions-item
                    :label="this.$store.state.user.lang === 'zh' ? deviceCfg.dataUnit.name : deviceCfg.dataUnit.keyword"
                    label-align="right">
                  {{ deviceCfg.dataValue === 65535 ? '-' : deviceCfg.dataValue + '\t' + deviceCfg.dataUnit.unit }}
                </el-descriptions-item>
              </template>
            </el-descriptions>
          </el-scrollbar>

        </el-collapse-item>
      </el-collapse>
    </div>
  </dv-border-box-12>
</template>
<script>
import {ref} from "vue";
import "element-plus/dist/index.css";
import {mapState, mapGetters} from "vuex"
import {formatTimeToStr, formatTimeDiff} from '@/utils/date.js'

import DeviceIcon from '@/components/pmsIcon/DeviceIcon.vue'
import {ElMessage} from "element-plus";
import {getCableName} from "@/utils/cableNameGen";
import { useI18n } from 'vue-i18n'

export default {
  name: "infoWindow",
  components: {
    DeviceIcon,
  },
  props: {
    map: Object,
    device: Object,
  },
  data() {
    const activeName = ref();
    const key = 1;
    const deviceData = {units: []};
    return {
      activeName,
      key,
      deviceData,
    };
  },
  methods: {
    translate(...args) {
      const { t } = useI18n()
      return t(...args)
    },
    copyCode(code) {
      navigator.clipboard.writeText(code)
      ElMessage({
        type: 'success',
        message: '设备编号已复制！'
      })
    },
    onClick(id) {
      this.$store.commit('cables/setCurrentDevice', id)
      this.$router.push('devicecenter')
    },
    getMapItemById(map, id) {
      let item = {
        id: id,
        name: id,
      }
      if (map.has(id)) {
        return map.get(id)
      }
      return item;
    },
    onChange() {
      this.$nextTick(function () {
        this.key++;
      });
    },
    onMouseOver() {
      this.map.setStatus({scrollWheel: false}); // 是否开始鼠标滚动缩放
    },
    onMouseLeave() {
      this.map.setStatus({scrollWheel: true}); // 是否开始鼠标滚动缩放
    },
    formatTimeToStr,
    formatTime(time) {
      if (!time) {
        return ''
      }
      return this.formatTimeToStr(time)
    },
    formatTimeDiff,
    updatedAt(deviceId) {
      let mostRecent = this.getDeviceValuesById(deviceId).time
      if (mostRecent === '0000-00-00 00:00:00' ) {
        return this.translate('mapCables.noData')
      }
      let t = this.formatTimeDiff(Date.now() - mostRecent * 1000)
      if (t.length > 0) {
        return this.translate('mapCables.updatedHowLongAgo', [t])
        // return `, 更新于${t}前`
      } else {
        return this.translate('mapCables.recentlyUpdated')
      }
    },
    getCableName,
    getInfoBoxTitle(cableName, deviceName) {
      if (this.$store.state.user.lang === 'zh') {
        return cableName + '，' + deviceName
      } else {
        return deviceName + ', ' + cableName
      }
    },
  },
  mounted() {
    if (this.device && this.device.subdevs && this.device.subdevs.length > 0) {
      this.activeName = this.device.subdevs[0].id
      if (this.device.subdevs[0].modelId === 9 && this.device.subdevs.length > 1) {
        this.activeName = this.device.subdevs[1].id
      } // 优先展开非边缘代理设备
      this.$nextTick(function () {
        this.key++;
      });
    }
  },
  computed: {
    ...mapState(
        ['cables', 'models']
    ),
    ...mapGetters(
        {
          getDeviceValuesById: 'cables/getDeviceValueById',
          getDeviceAlarmsById: 'cables/getDeviceAlarmsById',
          isDeviceAlarm: 'cables/isDeviceAlarm'
        },
    ),
  },
  watch: {
    '$store.state.cables.deviceClicked': {
      handler: function (v){
        this.activeName = v
        this.key++
      },
    },
  }
};
</script>
<style scoped>
#div-infoWindow {
  padding: 10px;
  margin: auto;
  top: 1px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 1);
}

#infoWindowBorder {
  padding: 10px;
}

.boxTitle {
  font-style: italic;
  text-align: right;
  line-height: 20px;
  padding-bottom: 5px;
}

.link {
  margin: auto;
  width: 99%;
  line-height: 20px;
}

.tag {
  margin-left: 10px;
}

.deviceicon {
  width: 40px;
  height: 34px;
  /*  !*display: flex;*!*/
  /*  !*justify-content: center;*!*/
  /*  !*align-items: center;*!*/
}
</style>

<style>
.el-collapse-item__content {
  padding-bottom: 5px !important;
}

.el-collapse-item__header {
  height: 40px !important;
}
</style>
